import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import SignaturePad from 'signature_pad'; // Signature library
import MainSpinner from '../components/MainSpinner'; // Make sure to adjust the path as necessary

const StaffInvitation = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    username: '',
    profile_picture_path: '',
    password: '',
    confirmPassword: '',
    user_role_id: '1', // Default to Admin
    partner_company_id: '', // Additional field for Driver
    trailer_type: '', // Additional field for Driver
    signature: '', // Additional field for the signature in base64
  });
  const [loading, setLoading] = useState(true);
  const [isDriver, setIsDriver] = useState(false); // To check if the selected role is "Driver"
  const signaturePadRef = useRef(null); // Reference for the signature canvas
  const canvasRef = useRef(null); // Reference for the canvas DOM

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the time as necessary

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isDriver && canvasRef.current) {
      // Initialize the signature_pad when the user is Driver
      const canvas = canvasRef.current;
      signaturePadRef.current = new SignaturePad(canvas);
    }
  }, [isDriver]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check if the selected role is "Driver"
    if (name === 'user_role_id' && value === '2') {
      setIsDriver(true);
    } else if (name === 'user_role_id') {
      setIsDriver(false);
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();

    if (isDriver && signaturePadRef.current) {
      // Capture the signature in base64
      const signatureData = signaturePadRef.current.toDataURL();
      setFormData((prevData) => ({
        ...prevData,
        signature: signatureData,
      }));
    }

    // Form submission logic
    console.log('Sending data:', formData);

    const payload = {
      ...formData,
      signature: formData.signature,
    };

    try {
      const userToken = localStorage.getItem('userToken');

      const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/partnercompany/invite_staff`,
        payload,
        {
          headers: {
            "Screen-Name": "/PartnerCompany",
            "Authorization": `Bearer ${userToken}`,
          }
        });
      console.log("Form successfully submitted:", response.data);
      Swal.fire({
        title: 'Success!',
        text: 'Form successfully submitted!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    } catch (error) {
      console.error("Error submitting the form:", error);

      let errorMessage = 'Request failed';
      if (error.response && error.response.data) {
        errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  if (loading) {
    return <MainSpinner />;
  }

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className=" w-96 p-8 bg-white rounded-lg shadow-lg mt-32"> {/* Reducing the form width */}
        <h2 className="text-xl font-bold mb-6 text-center">Employee Registration</h2>
        <form onSubmit={handleInvite}>
          <div className="mb-4">
            <label htmlFor="user_role_id" className="block text-sm font-medium text-gray-700">Role:</label>
            <select
              id="user_role_id"
              name="user_role_id"
              value={formData.user_role_id}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="1">Admin</option>
              <option value="7">Dispatcher</option>
              <option value="9">Accountant</option>
              <option value="2">Driver</option>
            </select>
          </div>
          
          <div className="mb-4">
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First Name:</label>
            <input
              id="first_name"
              name="first_name"
              type="text"
              value={formData.first_name}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='Enter first name'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">Last Name:</label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              value={formData.last_name}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='Enter last name'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='email@example.com'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone:</label>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='(123) 456-7890'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username:</label>
            <input
              id="username"
              name="username"
              type="text"
              value={formData.username}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='Enter username'
            />
          </div>

          {/* Fields specific to Driver */}
          {isDriver && (
            <>
              <div className="mb-4">
                <label htmlFor="partner_company_id" className="block text-sm font-medium text-gray-700">Partner Company ID:</label>
                <input
                  id="partner_company_id"
                  name="partner_company_id"
                  type="text"
                  value={formData.partner_company_id}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder='Enter Partner Company ID'
                />
              </div>
              <div className="mb-4">
                <label htmlFor="trailer_type" className="block text-sm font-medium text-gray-700">Trailer Type:</label>
                <input
                  id="trailer_type"
                  name="trailer_type"
                  type="text"
                  value={formData.trailer_type}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder='Enter trailer type'
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Signature:</label>
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={150}
                  className="border border-gray-300 rounded-md"
                ></canvas>
                <button
                  type="button"
                  onClick={clearSignature}
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Clear Signature
                </button>
              </div>
            </>
          )}

          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='Enter password'
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password:</label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder='Confirm password'
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 mt-4 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default StaffInvitation;
